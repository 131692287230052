body {
  margin: 0;
  padding: 0;
  font-family: Manrope;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: unset;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}
@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
