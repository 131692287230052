.search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .search-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-input input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
  }
  
  .search-input button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #008080;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .search-input .advanced-filter {
    background-color: transparent;
    color: #008080;
    border: 1px solid #008080;
  }
  
  .filter-options {
    display: flex;
    gap: 10px;
  }
  
  .filter-options button {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  